<template>
  <div>
    <v-row >
      <v-dialog v-model="dialogDelete" style="border: 100px white solid" max-width="380px"  transition="dialog-top-transition">
        <v-card class="pa-7">
          <v-card-title class="d-flex justify-center align-center">
            <v-row  dense>
              <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background">
                  <v-icon dense color="red" style="font-size: 30px" class="my-0">mdi-alert-outline</v-icon>
                </span>
              </v-col>
              <v-col cols="12">
                <span>{{ $t('deleteQuestion') }}</span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="text-center">{{ $t('questionTextForDelete') }}</v-card-text>
          <v-card-actions class="mb-2">
            <v-row dense>
              <v-col cols="12">
<!--            <v-btn color="red darken-1" text @click="deleteVerifierConfirm">{{ $t("yes") }}</v-btn>-->
                <v-btn block depressed color="red" style="color: white;" @click="deleteVerifierConfirm">{{ $t('deleteField') }}</v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn  outlined depressed color="black" block @click="closeDeleteDialog">{{ $t("cancel") }}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat style="border-radius: 12px">
          <v-data-table
              v-if="allVerifiers"
              class="pa-1"
              :loading="loading"
              :headers="headers"
              :headers-length="headers.length"
              :no-data-text="$t('no-data-available')"
              :loading-text="$t('loading')"
              :search="appBarSearch"
              :items="allVerifiers"
              fixed-header
              dense
              style="cursor: pointer; border-radius: 5px; border: #e1dce8 solid 1px"
              group-by="rvDomain"
              :height="pageHeight(130)"
              :single-expand="true"
              :expanded.sync="expanded"
              :footer-props="footerProps"
              :items-per-page="-1"
          >
            <template v-slot:item="{ item }">
              <tr style=" border-bottom: none;text-align: start">
                <td style="border-bottom: none;text-align: start">
                  {{ $i18n.locale === 'en' ? item.shortDescription : item.shortDescriptionEsp }}
                </td >
                <td style="border-bottom: none;text-align: start">{{ item.lowValue }}</td>
                <td style="border-bottom: none;text-align: end">
                  <v-menu
                      left
                      bottom
                      class="py-0"
                      offset-y
                      transition="slide-x-transition"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-btn
                          icon
                          color="black"
                          v-bind="attrs"
                          v-on="on"
                      >
                        <span class="mdi mdi-24px mdi-dots-horizontal mt-1"></span>
                      </v-btn>
                    </template >
                    <v-list class="px-0" dense >
                      <!-- <v-list-item link dense @click="synchronizeDrawers(items[0].patientId)"> -->
                      <v-list-item class="py-0 my-0" link dense @click="editDrawerDetails(item)">
                        <v-list-item-icon class="mx-1 ">
                          <v-icon class="icon-img mdi-18px" color="black">mdi-eye</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="font-bold" style="font-size: 14px">{{$t("view")}}</v-list-item-content>
                      </v-list-item>
                      <v-divider/>
                      <v-list-item link dense @click="deleteVerifierById(item)">
                        <v-list-item-icon class="mx-1 ">
                          <v-icon color="red">mdi-trash-can-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="font-bold" style="font-size: 14px">{{$t("delete")}}</v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
<!--                  <v-icon @click="editDrawerDetails(item)" color="primary">mdi-pencil</v-icon>-->
<!--                  <v-icon @click="deleteVerifierById(item)" color="primary">mdi-trash-can-outline</v-icon>-->
                </td>
              </tr>
            </template>
            <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }">
              <td @click="toggle" class="py-0 my-0 text-left" style="background-color: white;border-top: #ECEAF3 solid thin; border-bottom: none; " :colspan="headers.length">
                <v-row class="align-center d-flex">
                  <v-col class="py-0 pl-0" cols="4">
                    <v-btn small icon :ref="group" :data-open="isOpen">
                      <v-icon v-if="isOpen" color="primary">mdi-chevron-down</v-icon>
                      <v-icon v-else color="primary">mdi-chevron-right</v-icon>
                    </v-btn>
                    <span style="font-weight: bold; margin-left: 2px; margin-right: 8px">{{ group }}</span>
                  </v-col>
                  <v-col cols="7" class="py-0 my-0">
                    <v-chip pill x-small color="primary" class="mx-2 text--black custom-chip-size" label>
                      {{ items.length }} {{ items.length > 1 ? $t('verifires') : $t('verifier') }}
                    </v-chip>
                  </v-col>
                </v-row>
<!--                <span style="float: right;">-->
<!--                  <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">-->
<!--                    <v-icon v-if="isOpen" color="primary">mdi-arrow-up-drop-circle</v-icon>-->
<!--                    <v-icon v-else color="primary">mdi-arrow-down-drop-circle</v-icon>-->
<!--                  </v-btn>-->
<!--                </span>-->
<!--                <span style="font-weight: bold; margin-left: 2px; margin-right: 8px">-->
<!--                  {{ group }}-->
<!--                </span>-->
<!--                <v-chip  class="mb-1 white&#45;&#45;text" pill x-small color="primary">-->
<!--                  {{ items.length }}-->
<!--                </v-chip>-->
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

  <script>
  import { mapState, mapGetters } from 'vuex';
  import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
export default {
data: () => ({
  footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
  loading: false,
  headerClass: 'color-header',
  expanded: [],
  dialogDelete: false,
  verifierIdToDelete: -1,
}),
computed: {
...mapState({
  allVerifiers: (state) => state.verifiers.allverifires,
}),
...mapGetters({
  appBarSearch: 'filterbar/getSearch',
  pageHeight: 'pageHeight',
  // allVerifiers: 'verifiers/getAllVerifiers',
}),
headers () {
  var headers = [];
  headers = [
      {
        text: this.$t('name'),
        value: 'shortDescription',
        class: this.headerClass,
      },
    {
      text: this.$t('lowvalue'),
      value: 'lowValue',
      class: this.headerClass,
    },
    {
      text: '', // 	this.$t('shortDescription'),
      value: 'shortDescription',
      class: this.headerClass,
    },
  ];
  return headers;
  },
},
async mounted () {
  await this.getAllVerifiers();
},
methods: {
  closeAll () {
    Object.keys(this.$refs).forEach(k => {
      if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
        this.$refs[k].$el.click();
      }
    });
  },
  async getAllVerifiers () {
    this.closeAll();
    this.loading = true;
    await this.$store.dispatch('verifiers/getAllVerifiers').then(() => {
      this.loading = false;
    });
    this.closeAll();
    },
  async editDrawerDetails (item) {
    await this.$store.commit('verifiers/SELECTED_VERIFIER', item);
    this.$store.commit('SET_EDIT_DETAILS_DRAWER', {
      updateDrawer: true,
      page2: 'verifier',
      item: item,
    });
    },
async deleteVerifierById (item) {
    this.dialogDelete = true;
    this.verifierIdToDelete = item.id;
    },
  async deleteVerifierConfirm () {
    await this.$store.dispatch('verifiers/deleteVerifierById', this.verifierIdToDelete).then(res => {
      if (res.data.resFlag) {
        showSuccessAlert(this.$t('success-operation'));
        this.getAllVerifiers();
        this.closeDeleteDialog();
      } else {
        showErrorAlert(this.$t('failed-operation'));
      }
    });
    },
  closeDeleteDialog () {
    this.dialogDelete = false;
    this.verifierIdToDelete = -1;
  },
},
};
</script>
